import React, {Component} from 'react';
import Cells from "vanta/src/vanta.cells";
import Waves from "vanta/src/vanta.waves";
import Fog from "vanta/src/vanta.fog";
import Clouds from "vanta/src/vanta.clouds";
import Halo from "vanta/src/vanta.halo";
import bgimg from './assets/ov.png';

class App extends Component {

    constructor(props) {
        super(props);
        this.vantaRef = React.createRef();
    }

    state = {
        vantaEffect: null,
        effects: {
            waves: {
                fn: set => { return Waves(set)}, set: {
                    mouseControls: true,
                    touchControls: true,
                    gyroControls: false,
                    minHeight: 200.00,
                    minWidth: 200.00,
                    scale: 1.00,
                    scaleMobile: 1.00,
                    color: 0x98831b,
                    waveHeight: 15.50,
                    zoom: 1.52
                }
            },
            cells: {
                fn: set => { return Cells(set)}, set: {
                    mouseControls: true,
                    touchControls: true,
                    gyroControls: false,
                    minHeight: 200.00,
                    minWidth: 200.00,
                    scale: 1.00,
                    color1: 0x37372b,
                    color2: 0xffee00,
                    size: 5.00,
                    speed: 1.20
                }
            },
            fog: {
                fn: set => { return Fog(set)}, set: {
                    mouseControls: true,
                    touchControls: true,
                    gyroControls: false,
                    minHeight: 200.00,
                    minWidth: 200.00,
                    highlightColor: 0xfff000,
                    midtoneColor: 0x525f2a,
                    lowlightColor: 0x337d2f,
                    baseColor: 0xffe100,
                    blurFactor: 0.72,
                    speed: 1.60
                }
            },
            clouds: {
                fn: set => { return Clouds(set)}, set: {
                    mouseControls: true,
                    touchControls: true,
                    gyroControls: false,
                    minHeight: 200.00,
                    minWidth: 200.00,
                    skyColor: 0x2cbb78,
                    cloudColor: 0xd4b022,
                    cloudShadowColor: 0x25303c,
                    sunColor: 0xefff00,
                    sunGlareColor: 0xffe1e1,
                    sunlightColor: 0xe1a56f
                }
            },
            halo: {
                fn: set => { return Halo(set)}, set: {
                    mouseControls: true,
                    touchControls: true,
                    gyroControls: false,
                    minHeight: 200.00,
                    minWidth: 200.00,
                    baseColor: 0x89893a,
                    backgroundColor: 0xb6b61f,
                    amplitudeFactor: 3.00,
                    xOffset: -0.26,
                    size: 3.00
                }
            }
        }
    }

    renderButtons = () => {
        return Object.keys(this.state.effects).map((btn, index) => {
            return <button key={index} className="ui button primary" onClick={() => this.swapEffect(btn)}>{btn}</button>
        })
    }

    componentDidMount() {
        this.swapEffect('waves');
    }

    swapEffect = (effect) => {
        if(this.state.vantaEffect){
            this.state.vantaEffect.destroy();
        }
        const getEffect = this.state.effects[effect].fn;
        this.setState({vantaEffect: getEffect({
            el: this.vantaRef.current,
            ...this.state.effects[effect].set
        })});
        // console.log(this.state.vantaEffect);
    }

    componentWillUnmount() {
        if (this.state.vantaEffect) {
            this.state.vantaEffect.destroy();
        }
    }

    render() {
        return (
            <div className="App">
                <div className="ani-header" style={{padding: '8px'}}>
                    {this.renderButtons()}
                </div>
                <div className="ani-opacity">
                    <img src={bgimg} alt=""/>
                </div>
                <div className="ani-container" ref={this.vantaRef}>

                </div>
            </div>
        );
    }
}
export default App;
